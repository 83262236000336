import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Cabecalho from "../../components/cabecalho"
import getImage from "../../components/getImage"
import  { Container } from './index-styled'
import { useDicionary } from "../../context/dicionary"
import ProgressBar from "../../components/progressBar"
import Title from "../../components/styled-components/title"
import Footer from "../../components/footer"

const { ipcRenderer } = window.require('electron')

export default function Printer() {
    const location = useLocation()

    const navigate = useNavigate()
    let alreadyExecuted = false

    const { currentLeng } = useDicionary()

    const printTicket = () => {
        if (alreadyExecuted) {
            return;
        }
        
        ipcRenderer.send('save and print', location.state)

        alreadyExecuted = true;

        setTimeout(() => {
            navigate('/', undefined)
        }, 5000);
    }

    const cancelTimeout = () => {
        clearTimeout(process._timeout)  
    }


    
    useEffect(() => {
        printTicket()
        cancelTimeout()
        process._image = undefined
    }, [])

    return (
        <Container>
            <Cabecalho />
            <ProgressBar page={4}/>
            <div className="container2"> 
                <Title titleTxt={currentLeng.printPage}/>
                <img src={getImage("/src/assets/images/ticket.png")} alt=""/>
            </div>
            <Footer showCancelButton={false}/>
        </Container>
    )
}