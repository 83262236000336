import { useDicionary } from "../../context/dicionary";
import getImage from "../getImage";
import { Container, ContainerBox } from "./styled";




export default function ProgressBar({page}) { 
    const { currentLeng } = useDicionary();
    const pages = Object.values(currentLeng.progressBar)
    page -= 1

    return (
        <Container >
            {pages.map((item, index) => {
                let stat = ''
                if(index < page) {
                    stat = 'finished' 
                } else if (index === page) {
                    stat = 'doing'
                } else {
                    stat = 'waiting'
                }

                return <div className="box" key={index} > 
                    <Box content={item} number={index+1} status={stat} />
                    <div className={`line ${stat}`}> </div>
                </div>
            })}
        </Container>
    )
}

function Box({content, number, status}) {
    return (
        <ContainerBox>
            {status === 'finished'
            ? <div className={status} > <img src={getImage('/src/assets/images/completedArrow.png')} alt=""></img> </div> 
            : <div className={status}> {number} </div>}

            <div className={`content-${status}`} > {content} </div>
        </ContainerBox>
    )
}