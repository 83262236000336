import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    width: 100%;

    position: relative;

    .container-top {
        width: 100%;
        background-color: #46B6CA;

        position: relative;
        z-index: 1;
    }

    .position-shadow {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #46B6CA;

        position: relative;
    }

    .shadow-top {
            position: absolute;
            content: "";
            z-index: -1;

            box-shadow:0 0 20px 0px rgba(0,0,0,0.8);
            top: 0;
            bottom: 0px;
            left: 25px;
            right: 25px;

            border-radius: 12.5%;
        }

    .container3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 10vh;
        max-height: 125px;

        font-size: 1.75em;
        font-family: 'Kiro Extra Bold';
        font-weight: bold;

        color: white;

        margin: 0px;

        position: relative;
        background-color: #46B6CA;

        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;

        .shadow {
            position: absolute;
            content: "";
            z-index: -1;

            box-shadow:0 0 20px 0px rgba(0,0,0,0.8);
            top: 0;
            bottom: 0px;
            left: 25px;
            right: 25px;

            border-radius: 12.5%;
        }
    }


    .stickerLogo {
        width: 90px;
        height: 90px;
        background: none;
        margin: 10px 0;
    }

    .logoText {
        margin-left: 25px !important;
        letter-spacing: -.75px;


        font-size : 3.25rem;
        margin: 0;
        margin-left: 25px;
    }

    .idiomas{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 25px;
    }

    h1 {
        margin-top: 0px;
    }

    .show {
        display: none !important;
    }

    .image {
        width: 180px;
        height: 180px;

        background-color: #333333;
        border-radius: 15px;
    
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 15%;
        text-align: center;
    }

    .show-false {
        display: none !important;
    }

    .camera {
        width: 180px;
        height: 180px;

        background-color: #333333;
        border-radius: 15px;
    
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 15%;
        text-align: center;
    }

    .selected {
        img {
            opacity: 100% !important;
        }
    }


    .item-idioma {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        img {
            width: 50px;
            height: 50px;
            opacity: 50%;
        }
        

        :nth-child(2) {
            margin: 0px 1.75rem;
        }

        p {
            margin: 0px;

            font-size: .75em;
        }
    }
`

export { Container }