import styled from "styled-components";

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    .shadow {
        position: absolute;
        content: "";
        z-index: -3;

        box-shadow:0 0 10px rgba(0,0,0,0.8);
        top:50%;
        bottom:0;
        left: 5px;
        right: 5px;

        border-radius:125px / 10px;
    }
`

const TitleText = styled.div`
    width: 100%;
    padding: 5px 0px;
    
    color: white;
    font-family: 'Kiro Extra Bold';
    font-size: 1.75rem;
    height: 4vh;


    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #46B6CA;

    border-radius: 10px;
`

export { TitleText, TitleContainer }