import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;

    height: 100vh;

    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        flex-grow: 1;
        justify-content: flex-end;
        width: 100%;

        img {
            margin: 100px 0px;
            height: 250px;
            width: 250px;
        }

    }
`

export { Container }