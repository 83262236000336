import { useDicionary } from '../../context/dicionary'
import getImage from '../getImage'
import { KeyContainer } from './keyboard-styled'

export function Key(props) {
    return(
        <KeyContainer className={ props.letter.toLowerCase() } onClick={() => props.onDataChange(props.letter)}>
            <h4> {props.letter} </h4>
        </KeyContainer>
    )
}

export function KeyBackspace(props) {
    return(
        <KeyContainer className={ props.letter.toLowerCase() } onClick={() => props.onDataChange(props.letter)}>
            <img src={getImage("/src/assets/images/backpsace.svg")} alt=""/>
        </KeyContainer>
    )
}

export function KeyEnter(props) {
    const { currentLeng } = useDicionary();

    return(
        <KeyContainer className={ props.letter.toLowerCase() } onClick={() => props.onDataChange(props.letter)}>
            {currentLeng.enter}
        </KeyContainer>
    )
}

export function KeySpace(props) {
    return(
        <KeyContainer className={ props.letter.toLowerCase() } onClick={() => props.onDataChange(props.letter)}>
            <img src={getImage("/src/assets/images/KeySpace.png")} alt=""/>
        </KeyContainer>
    )
}