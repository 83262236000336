import {Routes, Route, HashRouter} from 'react-router-dom'

import Biometry from './pages/biometry'
import Photo from './pages/photo'
import Information from './pages/information'
import Printer from './pages/printer'

import DicionaryProvider from './context/dicionary'

export default function Router() {
    return (
        <DicionaryProvider>
            <HashRouter>
                <Routes>
                    <Route path='/' element={<Information />} />
                    <Route path='/biometry' element={<Biometry />} />
                    <Route path='/photo' element={<Photo />} />
                    <Route path='/printer' element={<Printer />} />
                </Routes>
            </HashRouter>
        </DicionaryProvider>
    )
}