import React, { createContext, useState, useContext } from "react";

const DicionaryContext = createContext();

export default function DicionaryProvider({ children }) {
  const [currentLeng, setCurrentLeng] = useState(process._dicionary['PT-BR']);

  return (
    <DicionaryContext.Provider
      value={{
        currentLeng,
        setCurrentLeng
      }}
    >
      {children}
    </DicionaryContext.Provider>
  );
}

export function useDicionary() {
  const context = useContext(DicionaryContext);

  if (!context) throw new Error("useDicionary must be used within a DicionaryProvider");
  const { currentLeng, setCurrentLeng } = context;
  return { currentLeng, setCurrentLeng };
}