import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 100vh;

    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        flex-grow: 1;
        justify-content: flex-end;

        width: 97.5%;
    

        .inputContainer {
            width: 80%;

            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 75px;

            position: relative;

            input {
                width: 100%;
                height: 4vh;
                padding: 5px 0px;
                
                color: white;
                font-family: 'Kiro Regular';
                font-size: 2rem;

                border: 0px solid transparent;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                background-color: #46B6CA;

                text-transform: capitalize;
                padding-left: 10px;

                border-radius: 10px;

                ::placeholder {
                    font-family: 'Kiro Extra Bold';
                    font-size: 2rem;
                    text-align: center;
                    color: white;
                }
            }

            .shadow {
                position: absolute;
                content: "";
                z-index: -3;

                box-shadow:0 0 10px rgba(0,0,0,0.8);
                top:50%;
                bottom:0;
                left: 5px;
                right: 5px;

                border-radius:125px / 10px;
            }
        }


    }
    
`

export { Container }