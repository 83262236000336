import { useNavigate } from 'react-router-dom'
import { useDicionary } from '../../context/dicionary'
import { Container } from './styled'


export default function Footer(props) {

    const navigate = useNavigate()
    const showCancelButton = props.showCancelButton ?? true

    const { currentLeng } = useDicionary()

    const goHome = () => {
        if (props.naoVoltar !== undefined) {
            return;
        }

        navigate('/', undefined)
    }

    return (
        <Container>
            <div className='footer'>
                <h3> {currentLeng.footer} </h3>
            </div>
            {showCancelButton === true ? <button className='cancel' onClick={() => goHome()}> {currentLeng.cancel} </button> : ""}
        </Container>
    )
}