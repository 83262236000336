import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > div, button {
        height: 50%;
    }

    margin-top: 10vh;

    .cancel {
        background-color: #C2272D;

        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }


    .footer {
        width: 100%;
        background-color: #46B6CA;
        margin-right: 20px;

        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        align-items: center;    
        justify-content: center;
        text-align: center;

        h3 {
            font-family: 'Kiro Extra Bold';
            color: white;
            margin: 0px 0px 0px 20px;
            font-size: 1.5em;

            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            letter-spacing: .75px;
        }
    }

    .cancel, .continue {
        border: 0px none;

        text-transform: uppercase;
        font-family: 'Kiro Extra Bold';
        color: white;
        font-size: 1.75em;

        text-decoration: none;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    }


    margin-top: auto;
`

export { Container }