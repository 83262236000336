import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Cabecalho from "../../components/cabecalho"
import Footer from "../../components/footer"

import  { Container } from './index-styled'

import getImage from '../../components/getImage'
import { useDicionary } from "../../context/dicionary"
import ProgressBar from "../../components/progressBar"
import Title from "../../components/styled-components/title"

window.require('winax')
window.capturaBio = () => new Promise(async (resolve, reject) => {
    try {
        const loMKAcquisition   = new global.ActiveXObject('AMK_MSO300.MKAcquisition')
        const loImage 		    = new global.ActiveXObject('AMK_MSO300.MKImage')
        const loPks 			= new global.ActiveXObject('AMK_MSO300.MKMinutiae')
        
        loImage.Create(416, 416, 1)
        
        loMKAcquisition.FingerCaption 		= "Insira o dedo indicador"
        loMKAcquisition.ShowQualityBar 		= true
        loMKAcquisition.UseConsolidation 	= false		// true = pedir digital 3x
        loMKAcquisition.fingerDetection 	= 1
        loMKAcquisition.standard			= 1
        loMKAcquisition.halfSize			= true
        loMKAcquisition.WindowCaption		= 'Captura de Digital'
        loMKAcquisition.matchingThreshold	= false
        loMKAcquisition.OnlyOneMatching		= true
        loMKAcquisition.UseJuvenileCoder	= 1
        loMKAcquisition.TimeoutAcquisition  = 5000
        
        loMKAcquisition.RunNoMatch(loImage, loPks)
        
        if (loMKAcquisition.Status !== 0) {
            return reject({descricao: "Falha na leitura da digital", errorId: "1"}) 
        }
        
        resolve(Buffer.from(loPks.rawDataAsVariant).toString('hex').toUpperCase())
    } catch (err) {
        reject({ descricao: "Erro Interno", errorId: "FRONBIO001" })
    }
})

export default function Biometry() {
    let alreadyExecuted = false
    const {currentLeng} = useDicionary()

    const navigate = useNavigate()
    const location = useLocation()

    const capturarBio = async () => {
        setTimeout(() => {
            if (alreadyExecuted) {
                return;
            }

            window.capturaBio().then(
                (result) => {
                    let template = "02" // Fixo minunicia
                    template += Buffer.from(String.fromCharCode(result.length), 'ascii').toString('hex').toUpperCase() // Tamanho da minunicia
                    template += "00" // Fixo
                    template += result // Template original

                    let templateAM = Buffer.from(String.fromCharCode(template.length / 2), 'ascii').toString('hex').toUpperCase()
                    templateAM = templateAM.padStart(4, '0')

                    navigate('/photo', {state: {...location.state, biometry: { template: template, templateAM: templateAM }} })
                }, (err) => {
                    navigate('/', undefined)

                    // pra caso queira pular a parte da digital (normalmente em ambiente de teste interno)
                    // const fakeTemplate = '026800622900625AFF80804277CEA26C8ECAA94E6AC5A47B7E656AAE70CB9ECC937D6E8E669948A06DB2508554C48BC9526561BE7D5B996AA4498A608F8A8E807F9788A18DA849B09B6E6DA65EA77DAD87BA52795CBF5BBC83CD6AC289FFFF93290491FFFFE7D64490ECFF'
                    // const fakeTemplateAM = '006B'
                    // navigate('/photo', {state: {...location.state, biometry: { template: fakeTemplate, templateAM: fakeTemplateAM }} })
                }
            )
            alreadyExecuted = true;
        }, 2000);
    }

    useEffect(() => {
        capturarBio()
    }, []); 

    return (
        <Container>
            <Cabecalho />
            <ProgressBar page={2}/>
            <div className="container2"> 
                <Title titleTxt={currentLeng.biometryPage} />
                <img src={getImage('/src/assets/images/arrowDown.png')} alt=""/>
            </div>
            <Footer naoVoltar={true}/>
        </Container>
    )
}