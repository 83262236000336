import styled from "styled-components";

export const KeyContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;
    background-color: white;
    color: black;

    font-size: 1.75em;
    font-family: "Kiro Regular";

    border-radius: 5px;

    box-shadow: 0 4px 2px -2px gray;
    text-transform: uppercase;

    h4 {
        margin: 0px;
    }

    img {
        width: 25px;
        height: 25px;
        fill: white;    
    }
`   

export const KeyboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  

    width: 100%;
    padding: 5px 10px;
    border-radius: 25px;

    background-color: #E6E6E6;

    div:first-child {
        margin-top: 0px;
    }

    .layer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .layer > div{
        margin: 5px;
    }

    .space {
       width: 250px;

       img {
            width: 40px;
            height: 40px;
        }
    }   

    .enter {
        width: 125px;
        background-color: #46B6CA;
        color: white;
        text-transform: capitalize;
    }

    .backspace {
        width: 75px;

        img {
            width: 25px;
            height: 25px;
            fill: white;    
        }

        background-color: #BBBEC3;
    }

    .empty {

    }
`

