import { KeyboardContainer } from './keyboard-styled'

import {Key, KeyBackspace, KeyEnter, KeySpace} from "./Keyboardkey"

const firstLayer = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'backspace']
const secondLayer = ['a','s','d','f','g','h','j','k','l', 'enter']
const thirdLayer = ['z','x','c','space', 'v','b','n', 'm']

export default function VirtualKeyboard(props) {

    const onDataChange = (letter) => {
        props.onDataChange(letter)
    }

    return (
        <KeyboardContainer>
                <div className='layer'> 
                    { firstLayer.map(letter => {
                            if(letter === 'backspace') 
                                return <KeyBackspace key={letter} letter={letter} onDataChange={onDataChange} />

                            return <Key key={letter} letter={letter} onDataChange={onDataChange} />
                        })
                    }   
                </div>
                <div className='layer'>
                        { secondLayer.map(letter => {
                            if (letter === 'enter')
                                return <KeyEnter key={letter} letter={letter} onDataChange={onDataChange} />

                            return <Key key={letter} letter={letter} onDataChange={onDataChange} />
                        }) 
                    }
                </div>    
                <div className='layer'>
                    { thirdLayer.map(letter => {
                            if (letter === 'space')
                                return <KeySpace key={letter} letter={letter} onDataChange={onDataChange} />

                            return <Key key={letter} letter={letter} onDataChange={onDataChange} />
                        }) 
                    }
                </div>
        </KeyboardContainer>
    )
}