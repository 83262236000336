import {useEffect, useRef } from 'react';
import { Container } from './styled-index'

import getImage from '../getImage'
import { useDicionary } from '../../context/dicionary';

export default function Cabecalho({ChildFunc}) {
    let videoRef = useRef(null)
    let canvaRef = useRef(null) 

    const {currentLeng, setCurrentLeng} = useDicionary()

    const startup = () => {
        navigator.mediaDevices.getUserMedia({  
            video: {
                width: 180,
                height: 180
            }, 
            audio: false
        })
        .then((stream) => {
            videoRef.srcObject = stream
        })
        .catch((err) => {
            console.log("An error occurred: " + err);
        });
    }

    const takePic = () => {
        let width = 300;
        let height = 150;
        
        let context = canvaRef.current.getContext('2d')

        context.drawImage(videoRef, 0, 0, width, height);

        let base64Image = canvaRef.current.toDataURL('image/jpeg');   
        process._image = base64Image
        
        const separator = base64Image.indexOf(',')

        base64Image = base64Image.substr(separator + 1)
        return base64Image
    }

    const changeLeng = (leng) => {
        setCurrentLeng(process._dicionary[leng])
        process._selectedLeng = leng
    }

    if (ChildFunc !== undefined) {
        ChildFunc.current = takePic
    }

    useEffect(() => {
        startup()
    }, [])

    return (
        <Container>
            <div className='container-top'>
                <div className='position-shadow'>
                    <img src={getImage("/src/assets/images/stickerCab.png")} alt="z" className='stickerLogo'/>
                    <div className='shadow-top'> </div>
                </div>
            </div>

            <div className='container3'>
                <h2 className='logoText'> {currentLeng.cabTitle} </h2>
                <video id="video" ref={(video) => videoRef = video} autoPlay width={180} height={180} className='camera'> </video>
                <img className={`image ${!process._image ? 'show' : ''}`} src={process._image} alt=""/>
                <canvas id="canvas" ref={canvaRef} className={`canvas`}> </canvas>
                <div className='idiomas'>
                    <div className={process._selectedLeng === 'PT-BR' ? 'item-idioma selected' : 'item-idioma'} onClick={() => changeLeng('PT-BR')}> 
                        <img src={getImage("/src/assets/images/brasilIcon.png")} alt="br"/>
                        <p> PT </p>
                    </div>
                    <div className={process._selectedLeng === 'ESP' ? 'item-idioma selected' : 'item-idioma'} onClick={() => changeLeng('ESP')}> 
                        <img src={getImage("/src/assets/images/spainIcon.png")} alt="esp"/>
                        <p> ES </p>
                    </div>
                    <div className={process._selectedLeng === 'ENG' ? 'item-idioma selected' : 'item-idioma'} onClick={() => changeLeng('ENG')}> 
                        <img src={getImage("/src/assets/images/euaIcon.png")} alt="eng"/>
                        <p> EN </p>
                    </div>
                </div>
                <div className='shadow' />
            </div>
        </Container>
    )
}