import { useLocation, useNavigate } from "react-router-dom"
import Cabecalho from "../../components/cabecalho"

import VirtualKeyboard from "../../components/virtualKeyboard/virtualKeyboard"

import  { Container } from './index-styled'
import { useEffect, useRef, useState } from "react"
import { useDicionary } from "../../context/dicionary"
import ProgressBar from "../../components/progressBar"
import Footer from "../../components/footer"

const { ipcRenderer } = window.require('electron')

export default function Information() {
    const location = useLocation()
    const navigate = useNavigate()
    const inputRef = useRef()

    const { currentLeng } = useDicionary();

    const [data, setData] = useState('')
    const [selectionStart, setSelectionStart] = useState(0);
    
    useEffect(() => {
        inputRef.current.setSelectionRange(selectionStart, selectionStart);
    })

    useEffect(() => {
        inputRef.current.focus()
    }, [])


    const startTimeout = () => {
        process._timeout = setTimeout(() => navigate('/'), ipcRenderer.sendSync('getTimeoutTime'))

        navigate('/biometry', {state: {...location.state, info: data} });
    }

    const onDataChange = (letter) => {
        const curPos = inputRef.current.selectionStart

        switch (letter.toLowerCase()) {
            case 'backspace':
                if (curPos === 0) return;
                setData((prev) => (prev.slice(0,curPos-1)+prev.slice(curPos)))
                setSelectionStart(curPos-1)
            break;
            case 'space': 
                if(data.length === 20)
                    return;

                setData((prev) => (prev.slice(0,curPos)+' '+prev.slice(curPos)))
                setSelectionStart(curPos+1)
            break;
            case 'enter':
                if(!data)
                    break;
                startTimeout()
            break;
            default:
                if(data.length === 20)
                    return;

                setData((prev) => (prev.slice(0,curPos)+ letter +prev.slice(curPos)))
                setSelectionStart(curPos+1)
            break;
        }

    }

    const handleLostFocus = () => {
        inputRef.current.focus()
    }
            
    const cancelTimeout = () => {
        if (process._timeout !== undefined)
            clearTimeout(process._timeout)
    }

    useEffect(() => {
        cancelTimeout()
    }, [])

    return (
        <Container>
            <Cabecalho />
            <ProgressBar page={1}/>
            <div className="container2">
                <div className="inputContainer">
                    <input 
                        maxLength={20} 
                        value={data} 
                        onChange={e => setData(e.target.value)} 
                        placeholder={currentLeng.dataPage}
                        ref={inputRef}
                        onBlur={handleLostFocus}
                    />
                    <div className="shadow"> </div>
                </div>
                
                {/* <TitleText> Digite seus dados abaixo </TitleText> */}
                <VirtualKeyboard onDataChange={onDataChange} />
            </div>
            <Footer showCancelButton={false}/>
        </Container>
    )
}