import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: row;   

    align-items: center;
    justify-content: center;

    margin: 15vh 0 5vh 0;

    .box {
        display: flex;
        flex-direction: row;
        
        align-items: center;
        justify-content: center;
    }

    .line {
        height: 5px;
        width: 50px;
        background-color: #E6E6E6;
        border-radius: 50px;

        margin: 0px 2.5em;
    }

    .finished {
        background-color: #45B6CA;
    }

    .doing {
        color: white;
    }

    .box:last-child {
        .line:last-child {
            display: none;
        }
    }

`

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1E2F57;

    font-family: 'Kiro Extra Bold';
    font-size: 1.5em;
    
    div:first-child {
        width: 115px;
        height: 115px;

        border-radius: 50%;
        margin-bottom: 15px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    > :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        
        width: 100px;
    }

    .finished {
        background-color: #45B6CA;

        img {
            width: 80px;
            height: 60px;
        }
    }

    .doing {
        background-color: #45B6CA;
        font-size: 1.5em;
    }

    .waiting {
        background-color: #E6E6E6;
        font-size: 1.5em;
    }

    .content-doing, .content-finished {
        color: #45B6CA;
    }

`

export { Container, ContainerBox }