import { useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Cabecalho from "../../components/cabecalho"
import Footer from "../../components/footer"
import getImage from "../../components/getImage"
import ProgressBar from "../../components/progressBar"

import Title from "../../components/styled-components/title"
import { useDicionary } from "../../context/dicionary"
import  { Container } from './index-styled'

export default function Photo(props) {
    const location = useLocation()
    const ChildFunc = useRef(null)
    const navigate = useNavigate()

    const { currentLeng } = useDicionary()

    useState(() => {
        process._takePicTimeout = setTimeout(() => {
            takePicture()
        }, 3000);

    }, [])

    const takePicture = () => {
        if (process._takePicTimeout !== undefined)
            clearTimeout(process._takePicTimeout)

        const base64Image = ChildFunc.current()
        navigate('/printer', {state:  {...location.state, image: base64Image} })
    }

    return (
        <Container>
            <Cabecalho ChildFunc={ChildFunc}/>
            <ProgressBar page={3}/>
            <div className="container2"> 
                <Title titleTxt={currentLeng.photoPage} />
                <img src={getImage("/src/assets/images/arrowUp.png")} alt=""/>
                <button onClick={() => takePicture()} > {currentLeng.takePicButton} </button>
            </div>
            <Footer />
        </Container>
    )
}