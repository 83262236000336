import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;

    height: 100vh;

    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        flex-grow: 1;
        justify-content: flex-end;

        img {
            margin: 100px 0px;
            height: 300px;
            width: 250px;
        }

        button {
            background-color: #46B6CA;
            border: 0px solid;
            border-radius: 15px;

            color: white;
            font-size: 1.75rem;
            font-family: "Kiro Bold";

            padding: 7.5px 0px;  
            width: 20%;
            height: 75px;

            margin-bottom: 30px;

            cursor: pointer;
        }

    }
`

export { Container }